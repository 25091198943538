export default function Profile() {
    return (
      <div className="bg-white">
        <div className="bg-gray-800">
          {/* Header section with select menu */}
          
        </div>
      </div>
    )
  }
  