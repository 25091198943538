import { useAuth0 } from "@auth0/auth0-react";

const LoginButton = () => {
  const {loginWithRedirect } = useAuth0();
  
  return <button onClick={() => loginWithRedirect()}
  type="button"
  className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700"
>
  Log In
</button>
  
  
};

export default LoginButton;
