import { InboxIcon, SparklesIcon } from '@heroicons/react/outline'


export default function Home() {
    return (
      <div>
        <Content />
      </div>
    )
  }

  function Content() {
    return (
      <div>
        <Testimonial />
      </div>
      
    )
  }

 
  function Testimonial () {
    return (
      <div className="relative bg-white pt-16 pb-32 overflow-hidden">
      <div className="relative">
        <div className="desktop:mx-auto desktop:max-w-7xl desktop:px-8 desktop:grid desktop:grid-cols-2 desktop:grid-flow-col-dense desktop:gap-24">
          <div className="px-4 max-w-xl mx-auto tablet:px-6 desktop:py-16 desktop:max-w-none desktop:mx-0 desktop:px-0">
            <div>
              <div>
                <span className="h-12 w-12 rounded-md flex items-center justify-center bg-indigo-600">
                  <InboxIcon className="h-6 w-6 text-white" aria-hidden="true" />
                </span>
              </div>
              <div className="mt-6">
                <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                  Stay on top of customer support
                </h2>
                <p className="mt-4 text-lg text-gray-500">
                  Semper curabitur ullamcorper posuere nunc sed. Ornare iaculis bibendum malesuada faucibus lacinia
                  porttitor. Pulvinar laoreet sagittis viverra duis. In venenatis sem arcu pretium pharetra at. Lectus
                  viverra dui tellus ornare pharetra.
                </p>
                <div className="mt-6">
                  <a
                    href="a"
                    className="inline-flex px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700"
                  >
                    Get started
                  </a>
                </div>
              </div>
            </div>
            <div className="mt-8 border-t border-gray-200 pt-6">
              <blockquote>
                <div>
                  <p className="text-base text-gray-500">
                    &ldquo;Cras velit quis eros eget rhoncus lacus ultrices sed diam. Sit orci risus aenean curabitur
                    donec aliquet. Mi venenatis in euismod ut.&rdquo;
                  </p>
                </div>
                <footer className="mt-3">
                  <div className="flex items-center space-x-3">
                    <div className="flex-shrink-0">
                      <img
                        className="h-6 w-6 rounded-full"
                        src="https://images.unsplash.com/photo-1509783236416-c9ad59bae472?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80"
                        alt=""
                      />
                    </div>
                    <div className="text-base font-medium text-gray-700">Marcia Hill, Digital Marketing Manager</div>
                  </div>
                </footer>
              </blockquote>
            </div>
          </div>
          <div className="mt-12 tablet:mt-16 desktop:mt-0">
            <div className="pl-4 -mr-48 tablet:pl-6 laptop:-mr-16 desktop:px-0 desktop:m-0 desktop:relative desktop:h-full">
              <img
                className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 desktop:absolute desktop:left-0 desktop:h-full desktop:w-auto desktop:max-w-none"
                src="https://tailwindui.com/img/component-images/inbox-app-screenshot-1.jpg"
                alt="Inbox user interface"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="mt-24">
        <div className="desktop:mx-auto desktop:max-w-7xl desktop:px-8 desktop:grid desktop:grid-cols-2 desktop:grid-flow-col-dense desktop:gap-24">
          <div className="px-4 max-w-xl mx-auto tablet:px-6 desktop:py-32 desktop:max-w-none desktop:mx-0 desktop:px-0 desktop:col-start-2">
            <div>
              <div>
                <span className="h-12 w-12 rounded-md flex items-center justify-center bg-indigo-600">
                  <SparklesIcon className="h-6 w-6 text-white" aria-hidden="true" />
                </span>
              </div>
              <div className="mt-6">
                <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                  Better understand your customers
                </h2>
                <p className="mt-4 text-lg text-gray-500">
                  Semper curabitur ullamcorper posuere nunc sed. Ornare iaculis bibendum malesuada faucibus lacinia
                  porttitor. Pulvinar laoreet sagittis viverra duis. In venenatis sem arcu pretium pharetra at. Lectus
                  viverra dui tellus ornare pharetra.
                </p>
                <div className="mt-6">
                  <a
                    href="b"
                    className="inline-flex px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700"
                  >
                    Get started
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-12 tablet:mt-16 desktop:mt-0 desktop:col-start-1">
            <div className="pr-4 -ml-48 tablet:pr-6 laptop:-ml-16 desktop:px-0 desktop:m-0 desktop:relative desktop:h-full">
              <img
                className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 desktop:absolute desktop:right-0 desktop:h-full desktop:w-auto desktop:max-w-none"
                src="https://tailwindui.com/img/component-images/inbox-app-screenshot-2.jpg"
                alt="Customer profile user interface"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    )
  }