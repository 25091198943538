import { Link, Route, Routes, useMatch, useParams } from 'react-router-dom'
import Content from './Content'
import { Product_Detail } from './Detail'
import Header from './Header'



export default function Products() {
    return (
      <div className="">
      <Routes>
        <Route path="" element={<><Header/><Content /></>} />
        <Route path=":id/*" element={<Product_Detail/>} />
      </Routes>
      </div>
    )
}