import './App.css';
import Products from './Components/Routes/Products/Products';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import Home from './Components/Routes/Home';
import Pricing from './Components/Routes/Pricing';
import Profile from './Components/Routes/Profile';
import Team from './Components/Routes/Team';
import Navbar from './Components/Navbar';
import Footer from './Components/Footer';




function App() {

  
  return (
    <Router>
  
      <div>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="products/*" element={<Products />} />
          <Route path="pricing" element={<Pricing />} />
          <Route path="profile" element={<Profile />} />
          <Route path="team" element={<Team />} />
        </Routes>
        <Footer />
      </div>
      
    </Router>
  );
}

export default App;


