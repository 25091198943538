
import { CubeIcon } from "@heroicons/react/outline";




export default function Header() {
    return (

      <div className="flex flex-col items-center min-h-full ">
            <div className="w-11/12 tablet:max-w-2xl laptop:max-w-5xl pt-6">
              <div className="flex flex-row px-3">
                <div className="px-2">
                  <CubeIcon className="flex-shrink-0 h-8 w-8" /> 
                </div>
                <h2 className="text-xl font-semibold text-gray-A900 dark:text-dark-contrastText m-0">
                  Courses
                </h2>
                

              </div>
        </div>
      </div>
    )
}