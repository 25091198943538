import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';




export default function Content() {
    const [isLoaded, setLoaded] = useState(false);
    const { getAccessTokenSilently, isAuthenticated, isLoading } = useAuth0();

    const [courses, setCourses] = useState([{
        "id": "test",
        "name": null,
        "cover_image": 'https://www.educative.io/cdn-cgi/image/f=auto,fit=cover,w=620/v2api/collection/10370001/6006542571667456/image/4807730657755136',
        "module_count": null,
        "description": null,
    }]);
    
    useEffect(
        () => {
            if(isAuthenticated){
                const differentAudienceOptions = {
                    audience: 'https://api.getskilltree.io',
                    scope: 'read:rules'
                  };
                getAccessTokenSilently(differentAudienceOptions)
                .then((token) => {
                    fetch("https://api.getskilltree.io/v1/courses",
                    {
                        headers: {
                        Authorization: `Bearer ${token}`,
                        }
                    })
                    .then((response) => response.json())
                    .then((data) => {
                        setCourses(data)
                    })
                    .then(() => setLoaded(true))
                })
            } else {
                fetch(`https://api.getskilltree.io/v1/courses`)
                    .then((response) => response.json())
                    .then((data) => {
                        setCourses(data)
                    })
                    .then(() => setLoaded(true))
            }
            
        },
        [isAuthenticated, getAccessTokenSilently]
    )
    if (!isLoaded || isLoading) {
        return(
        <div className="flex flex-col items-center min-h-full ">
            <div className="w-11/12 tablet:max-w-2xl laptop:max-w-5xl pt-2 pb-4 px-2">
                <div className="grid grid-cols-1 tablet:grid-cols-2 laptop:grid-cols-3 desktop:grid-cols-3">
                
                <div className="my-3 px-3">
                            <div className="text-black bg-white dark:text-dark-contrastText rounded overflow-hidden flex flex-initial flex-col flex-shrink-0 transition transform hover:-translate-y-1.5 shadow-md hover:shadow-2xl duration-500 dark:bg-dark-80 ">
                                {/* <img src={course.cover_image} alt="Course Cover" className="aspect-auto"></img> */}
                                <div className="h-36 bg-gray-100"></div>
                                <div className="flex flex-col mt-2 px-4 py-2 h-60 ">
                                <div className="bg-gray-100 h-8 w-24">    
                                </div>
                                    <div className="overflow-hidden flex justify-between items-center max-h-24">
                                        <div className="mt-2 bg-gray-100 h-4 w-64">    
                                        </div>
                                        
                                    </div>
                                    <div className="mt-2 bg-gray-100 h-4 w-32">    
                                        </div>
                                    <div className="flex items-center justify-between mt-auto mb-4">
                                        <div className="bg-gray-100 h-8 w-32">    
                                        </div>
                                        
                                    </div>
                                </div>
                            </div> 
                </div>
                <div className="my-3 px-3">
                            <div className="text-black bg-white dark:text-dark-contrastText rounded overflow-hidden flex flex-initial flex-col flex-shrink-0 transition transform hover:-translate-y-1.5 shadow-md hover:shadow-2xl duration-500 dark:bg-dark-80 ">
                                {/* <img src={course.cover_image} alt="Course Cover" className="aspect-auto"></img> */}
                                <div className="h-36 bg-gray-100"></div>
                                <div className="flex flex-col mt-2 px-4 py-2 h-60 ">
                                <div className="bg-gray-100 h-8 w-24">    
                                </div>
                                    <div className="overflow-hidden flex justify-between items-center max-h-24">
                                        <div className="mt-2 bg-gray-100 h-4 w-64">    
                                        </div>
                                        
                                    </div>
                                    <div className="mt-2 bg-gray-100 h-4 w-32">    
                                        </div>
                                    <div className="flex items-center justify-between mt-auto mb-4">
                                        <div className="bg-gray-100 h-8 w-32">    
                                        </div>
                                        
                                    </div>
                                </div>
                            </div> 
                </div>
                <div className="my-3 px-3">
                            <div className="text-black bg-white dark:text-dark-contrastText rounded overflow-hidden flex flex-initial flex-col flex-shrink-0 transition transform hover:-translate-y-1.5 shadow-md hover:shadow-2xl duration-500 dark:bg-dark-80 ">
                                {/* <img src={course.cover_image} alt="Course Cover" className="aspect-auto"></img> */}
                                <div className="h-36 bg-gray-100"></div>
                                <div className="flex flex-col mt-2 px-4 py-2 h-60 ">
                                <div className="bg-gray-100 h-8 w-24">    
                                </div>
                                    <div className="overflow-hidden flex justify-between items-center max-h-24">
                                        <div className="mt-2 bg-gray-100 h-4 w-64">    
                                        </div>
                                        
                                    </div>
                                    <div className="mt-2 bg-gray-100 h-4 w-32">    
                                        </div>
                                    <div className="flex items-center justify-between mt-auto mb-4">
                                        <div className="bg-gray-100 h-8 w-32">    
                                        </div>
                                        
                                    </div>
                                </div>
                            </div> 
                </div>

                </div>
            </div>
        </div>
        )
    }

    
    return (
        <div className="flex flex-col items-center min-h-full ">
            <div className="w-11/12 tablet:max-w-2xl laptop:max-w-5xl pt-2 pb-4 px-2">
                <div className="grid grid-cols-1 tablet:grid-cols-2 laptop:grid-cols-3 desktop:grid-cols-3">
                {courses.map((course) => (
                    
                    <Link to={course.id} key={course.id}>
                        <div className="my-3 px-3">
                            <div className="text-black bg-white dark:text-dark-contrastText rounded overflow-hidden flex flex-initial flex-col flex-shrink-0 transition transform hover:-translate-y-1.5 shadow-md hover:shadow-2xl duration-500 dark:bg-dark-80 ">
                                <img src={course.cover_image} alt="Course Cover" className="aspect-auto"></img>
                                <div className="flex flex-col mt-2 px-4 py-2 h-60 ">
                                    <h2 className="text-xl font-semibold my-2">{course.name}</h2>
                                    <div className="overflow-hidden flex justify-between items-center max-h-24">
                                        <p className="font-normal text-sm">
                                        {course.description}
                                        </p>
                                    </div>
                                    <div className="flex items-center justify-between mt-auto mb-4">
                                        <div className="text-base font-bold mb-0">
                                            {course.module_count} Lessons
                                        </div>
                                        <button
                                            type="button"
                                            className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-base font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 "
                                        >
                                            Explore
                                        </button>
                                    </div>
                                </div>
                            </div> 
                        </div>
                    </Link>
                    
                ))}
                

                </div>
            </div>
        </div>
       
   
    )
  }