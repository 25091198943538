
import { useAuth0 } from "@auth0/auth0-react";
import { Listbox, Transition } from "@headlessui/react";
import { CubeTransparentIcon, ViewGridIcon, LockClosedIcon, MapIcon, SelectorIcon, CheckIcon } from "@heroicons/react/outline";
import { Fragment, useEffect, useState, } from "react";
import { Link, Route, Routes, useParams } from "react-router-dom";



export function Product_Detail() {
    let { id } = useParams();
    const { getAccessTokenSilently, isAuthenticated, isLoading } = useAuth0();

    const [isLoaded, setLoaded] = useState(false);
    const [course, setCourse] = useState({
        "id": "",
        "name": null,
        "module_count": null,
        "description": null,
        "detailed_description": null,
        "modules": [
            {
                "id": "",
                "name": "",
                "current": true
            },

        ]
    });

    let course_placeholder = <div className="min-h-screen">
                        <div className="flex flex-col items-center">
                        <div className="w-full max-w-8xl py-2 px-2 laptop:py-4 laptop:px-6">
                        
                        {/* Header Stuff */}
                        <div className="mx-auto tablet:px-6 tablet:max-w-full laptop:max-w-full desktop:max-w-7xl desktop:px-8 desktop:py-2"> 
                            <div className="overflow-hidden  py-8 mb-6 ">
                            <div className="flex flex-wrap-reverse items-center ">
                                <div className="flex flex-col flex-grow  px-3 tablet:w-6/12 laptop:w-5/12 ">
                                <div className="text-4xl laptop:text-5xl text-black font-extrabold mb-5 mt-5 laptop:mt-0 bg-gray-100 h-12 w-128">
                                    
                                </div>
                                <div className="text-4xl laptop:text-5xl text-black font-extrabold mb-5 mt-5 laptop:mt-0 bg-gray-100 h-12 w-64">
                                    
                                </div>
                                
                                <div className="text-gray-400 font-medium mb-4 bg-gray-100 h-4 w-128">
                                </div>
                                <div className="text-gray-400 font-medium mb-4 bg-gray-100 h-4 w-128">
                                </div>
                                <div className="text-gray-400 font-medium mb-4 bg-gray-100 h-4 w-128">
                                </div>
                                <div className="text-gray-400 font-medium mb-4 bg-gray-100 h-4 w-128">
                                </div>
                                <div className="text-gray-400 font-medium mb-4 bg-gray-100 h-4 w-64">
                                </div>
                                
                                </div>
                                <div className="flex flex-col flex-grow px-6 tablet:w-6/12 laptop:w-5/12 ">
                                
                                </div>
                                
                            </div>
                            <div className="relative">
                                <div className="py-8 px-2 absolute inset-0 flex items-center" aria-hidden="true">
                                <div className="w-full border-t-2 border-b border-gray-100" />
                                </div>
                            </div>

                            </div>
                        </div>

                        
                    

                        
                        </div>
                    </div>
                </div>

    let welcome = <div className="flex flex-col bg-white">
                    <div className="flex flex-col w-full shadow-md rounded border border-solid border-gray-100">
                        <div className="w-full h-2 rounded-t bg-gray-100"> 
                        </div>
                        <div className="px-6 pt-6 pb-4 flex flex-col w-full bg-white "> 
                            <div className="flex items-center mb-2">
                                <CubeTransparentIcon className="flex-shrink-0 -ml-1 mr-3 h-8 w-8 text-gray-100" /> 
                                <div className="m-0 font-bold tracking-widest uppercase"> </div>

                            </div>
                            <div className="text-base mt-4 mb-4 text-gray-900 dark:text-gray-500"></div>
                            <div className="flex items-center justify-end h-8"> 
                                
                            </div>
                        </div>
                        <div className="px-6 pt-6 pb-4 flex flex-col w-full">
                        <nav className="space-y-2" aria-label="Sidebar">
                            <div className="bg-gray-100 h-6 w-128"></div>
                            <div className="bg-gray-100 h-6 w-128"></div>
                            <div className="bg-gray-100 h-6 w-128"></div>
                            <div className="bg-gray-100 h-6 w-128"></div>
                            <div className="bg-gray-100 h-6 w-128"></div>
                            <div className="bg-gray-100 h-6 w-128"></div>
                        </nav>
                        </div>
                        
                    </div>
                </div>
    
    useEffect(
        () => {
            if(isAuthenticated){
                const differentAudienceOptions = {
                    audience: 'https://api.getskilltree.io',
                    scope: 'read:rules'
                  };
                getAccessTokenSilently(differentAudienceOptions)
                .then((token) => {
                    console.log("logged in")
                    fetch(`https://api.getskilltree.io/v1/courses/${id}`,
                    {
                        headers: {
                        Authorization: `Bearer ${token}`,
                        }
                    })
                    .then((response) => response.json())
                    .then((data) => {
                        setCourse(data)
                    })
                    .then(() => setLoaded(true))
                })
            } else {
                console.log("not logged in")
                fetch(`https://api.getskilltree.io/v1/courses/${id}`)
                    .then((response) => response.json())
                    .then((data) => {
                        setCourse(data)
                    })
                    .then(() => setLoaded(true))
            }
        },
        [id, isAuthenticated, getAccessTokenSilently]
    )

    if (!isLoaded || isLoading) {
        return course_placeholder
    }

    return (
     
      <div className="min-h-screen bg-gray-50">
          <div className="flex flex-col items-center">
          <div className="w-full max-w-8xl py-2 px-2 laptop:py-4 laptop:px-6">
            
            {/* Header Stuff */}
            
            <div className="mx-auto tablet:px-6 tablet:max-w-full laptop:max-w-full desktop:max-w-7xl desktop:px-8 "> 
            
                <div className="overflow-hidden mt-8 py-8 px-4 mx-3 mb-10 bg-white shadow-md rounded border border-solid border-gray-100">
                
                <div className="flex flex-wrap-reverse items-center ">
                    <div className="flex flex-col flex-grow  px-3 laptop:w-6/12 ">
                        <div className="text-4xl desktop:text-5xl text-black font-extrabold mb-5 mt-5 laptop:mt-0">
                            {course.name}
                        </div>
                        <div className="flex flex-wrap items-center -ml-4">
                            {/* How many videos and length */}
                        </div>
                        <div className="text-gray-400 font-medium mb-4">
                            {course.detailed_description}
                        </div>
                        <div className="flex flex-col laptop:flex-row tablet:items-center">
                            <button
                            type="button"
                            className="inline-flex justify-center items-center px-12 py-2 border border-gray-100 text-sm font-medium rounded-md shadow-sm text-white bg-indigo-500 hover:bg-indigo-600 "
                            >
                            Get full access for $150 / year
                            </button>

                        </div>
                    </div>
                    
                    <div className="flex flex-col flex-grow px-3 laptop:px-6 laptop:w-6/12 ">
                    <div className="aspect-video rounded-lg bg-gray-100 overflow-hidden tablet:aspect-video ">
                            <iframe src="https://player.vimeo.com/video/725816446?h=d7ee898d00&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" width="100%" height="100%" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" title="TBD Test Video"></iframe>                        <script src="https://player.vimeo.com/api/player.js"></script>
                    </div>
                    </div>
                    
                </div>
              

                </div>
            </div>

            {/* Core Content */}
            <div>
                <div className="max-w-3xl mx-auto tablet:px-6 tablet:max-w-full desktop:max-w-7xl laptop:max-w-full desktop:px-8 laptop:grid laptop:grid-cols-10 "> 
                    {/* Vertical Navigation */}
                    <div className="laptop:block px-3 laptop:col-span-3 "> 
                        <div className="flex flex-col sticky laptop:mr-6 top-20">
                            <ModulesNav id={course.id} /> 
                        </div>
                    </div>
                    <div className="laptop:col-span-7 px-3 "> 
                    {/* Vertical Navigation */}
                    <Routes>
                        <Route path="" element={welcome} />
                        
                        <Route path=":module_id/*" element={<ModuleView/>} />
                    </Routes>
                   
                </div>
                
            </div>
            </div>


            <div className="mx-auto py-8 tablet:px-6 tablet:max-w-full laptop:max-w-full desktop:max-w-7xl desktop:px-8 desktop:py-8 "> 
            
                <div className="overflow-hidden py-8 px-4 mx-3 mt-3 mb-6 bg-white shadow-md rounded border border-solid border-gray-100">
                <div className="flex flex-col space-y-2">
                            <div className="bg-gray-100 h-6 w-128"></div>
                            <div className="bg-gray-100 h-6 w-128"></div>
                            <div className="bg-gray-100 h-6 w-128"></div>
                            <div className="bg-gray-100 h-6 w-128"></div>
                            <div className="bg-gray-100 h-6 w-128"></div>
                            <div className="bg-gray-100 h-6 w-128"></div>
                </div>

            </div>
            </div>


            
          </div>
        </div>
      </div>
    )
  }

  
  function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
  }
  
function ModulesNav(props: {id: string}) {
    const { getAccessTokenSilently, isAuthenticated, isLoading } = useAuth0();
    const [isLoaded, setLoaded] = useState(false);
    const [course, setCourse] = useState({
        "id": null,
        "name": null,
        "module_count": null,
        "description": null,
        "detailed_description": null,
        "modules": [
            {
                "id": "",
                "name": "",
                "current": false
            },

        ]
    });

    useEffect(
        () => {
            if(isAuthenticated){
                const differentAudienceOptions = {
                    audience: 'https://api.getskilltree.io',
                    scope: 'read:rules'
                  };
                getAccessTokenSilently(differentAudienceOptions)
                .then((token) => {
                    fetch(`https://api.getskilltree.io/v1/courses/${props.id}`,
                    {
                        headers: {
                        Authorization: `Bearer ${token}`,
                        }
                    })
                    .then((response) => response.json())
                    .then((data) => {
                        setCourse(data)
                    })
                    .then(() => setLoaded(true))
                })
            } else {

                fetch(`https://api.getskilltree.io/v1/courses/${props.id}`)
                    .then((response) => response.json())
                    .then((data) => {
                        setCourse(data)
                    })
                    .then(() => setLoaded(true))
            }


         
        },
        [props.id, isAuthenticated, getAccessTokenSilently]
    )

    if (!isLoaded || isLoading) {
        return (
            <div className="flex flex-col bg-white">
            <div className="flex flex-col w-full shadow-md rounded border border-solid border-gray-100">
                <div className="w-full h-2 rounded-t bg-gray-100"> 
                </div>
                
                <div className="px-6 pt-6 pb-6 flex flex-col w-full">
                <nav className="space-y-2" aria-label="Sidebar">
                    <div className="bg-gray-100 h-6 w-64"></div>
                    <div className="bg-gray-100 h-6 w-64"></div>
                    <div className="bg-gray-100 h-6 w-64"></div>
                    <div className="bg-gray-100 h-6 w-64"></div>
                    
                </nav>

                </div>
                
            </div>
        </div>
        )

    }

    
    
    return (
        <div>

            <div className="flex flex-col bg-white mb-6 laptop:mb-0">
                <div className="w-full h-2 rounded-t bg-indigo-500"> 
                    </div>
                <div className="flex overflow-x-auto laptop:flex-col w-full shadow-md rounded-b border border-solid border-gray-100">
                    
                    <div className="px-8 py-3 laptop:pt-6 flex flex-col w-full "> 
                        <div className="flex items-center">
                            <MapIcon className="flex-shrink-0 -ml-1 mr-3 h-8 w-8 text-indigo-500" /> 
                            <div className="m-0 font-bold tracking-widest uppercase"> Overview </div>
                        </div>
                        
                    </div> 
                    <div className="px-6 pt-3 pb-3 flex laptop:flex-col w-full">
                    <nav className="space-y-2" aria-label="Sidebar">
                        <Link to="" key="1" >
                            <div
                            
                                className={classNames(
                                window.location.pathname.endsWith(props.id) ? 'bg-gray-100 text-gray-900' : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                                'group flex items-center px-3 py-2 text-sm font-medium rounded-md'
                                )}
                            >
                                <span className="truncate">Welcome!</span>
                            </div>
                        </Link>

                        
                        
                    </nav>

                    </div>
                    <div className="px-8 pt-3 flex flex-col w-full "> 
                        <div className="flex items-center">
                            <ViewGridIcon className="flex-shrink-0 -ml-1 mr-3 h-8 w-8 text-indigo-500" /> 
                            <div className="m-0 font-bold tracking-widest uppercase"> Modules </div>
                        </div>
                        
                    </div>
                    <div className="px-6 pt-3 pb-3 flex  laptop:flex-col w-full">
                        <nav className="space-x-8 flex laptop:flex-col laptop:space-x-0 laptop:space-y-1" aria-label="Sidebar">
                            {course.modules.map((module) => (
                            <Link to={module.id} key={module.id} >
                            <div
                            
                                className={classNames(
                                window.location.pathname.endsWith(module.id) ? 'bg-gray-100 text-gray-900' : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                                'group flex items-center px-3 py-2 text-sm font-medium rounded-md'
                                )}
                            >
                                <span className="truncate">{module.name}</span>
                            </div>
                            </Link>
                            ))}
                        </nav>

                    </div>
                    
                    
                </div>
            </div>
        </div>
      
    )
  }

function ModuleView() {
    let { module_id } = useParams();
    const { getAccessTokenSilently, isAuthenticated, isLoading } = useAuth0();


    const [isLoaded, setLoaded] = useState(false);
    const [module, setModule] = useState({
        "id": null,
        "name": null,
        "description": null,
        "lessons": [
            {
                "id": "",
                "name": "",
                "current": false
            },

        ]
    });

    

    useEffect(
        () => {
            if(isAuthenticated){
                const differentAudienceOptions = {
                    audience: 'https://api.getskilltree.io',
                    scope: 'read:rules'
                  };
                  setLoaded(false)
                getAccessTokenSilently(differentAudienceOptions)
                .then((token) => {
                    fetch(`https://api.getskilltree.io/v1/modules/${module_id}`,
                    {
                        headers: {
                        Authorization: `Bearer ${token}`,
                        }
                    })
                    .then((response) => response.json())
                    .then((data) => {
                        setModule(data)
                    })
                    .then(() => setLoaded(true))
                })
            } else {
                setLoaded(false)
                fetch(`https://api.getskilltree.io/v1/modules/${module_id}`)
                    .then((response) => response.json())
                    .then((data) => {
                        setModule(data)
                    })
                    .then(() => setLoaded(true))
            }

           
        },[module_id, isAuthenticated, getAccessTokenSilently]
        
    )

    if (!isLoaded || isLoading) {
        return (
            <div className="flex flex-col bg-white">
            <div className="flex flex-col w-full shadow-md rounded border border-solid border-gray-100">
                <div className="w-full h-2 rounded-t bg-gray-100"> 
                </div>
                <div className="px-6 pt-6 pb-4 flex flex-col w-full  "> 
                    <div className="flex items-center mb-2">
                        <CubeTransparentIcon className="flex-shrink-0 -ml-1 mr-3 h-8 w-8 text-gray-100" /> 
                        <div className="m-0 font-bold tracking-widest uppercase"> </div>

                    </div>
                    <div className="text-base mt-4 mb-4 text-gray-900 dark:text-gray-500"></div>
                    <div className="flex items-center justify-end h-8"> 
                        
                    </div>
                </div>
                <div className="px-6 pt-6 pb-4 flex flex-col w-full">
                <nav className="space-y-2" aria-label="Sidebar">
                    <div className="bg-gray-100 h-6 w-128"></div>
                    <div className="bg-gray-100 h-6 w-128"></div>
                    <div className="bg-gray-100 h-6 w-128"></div>
                    <div className="bg-gray-100 h-6 w-128"></div>
                    <div className="bg-gray-100 h-6 w-128"></div>
                    <div className="bg-white h-6 w-128"></div>
                    <div className="bg-gray-100 h-6 w-128"></div>
                    <div className="bg-gray-100 h-6 w-128"></div>
                    <div className="bg-gray-100 h-6 w-128"></div>
                    <div className="bg-gray-100 h-6 w-128"></div>
                    <div className="bg-gray-100 h-6 w-128"></div>
                    <div className="bg-gray-100 h-6 w-128"></div>
                    <div className="bg-white h-6 w-128"></div>
                    <div className="bg-gray-100 h-6 w-128"></div>
                    <div className="bg-gray-100 h-6 w-128"></div>
                    <div className="bg-gray-100 h-6 w-128"></div>
                    <div className="bg-gray-100 h-6 w-128"></div>
                    <div className="bg-gray-100 h-6 w-128"></div>
                    <div className="bg-gray-100 h-6 w-128"></div>
                </nav>
                </div>
                
            </div>
        </div>
        )
    }
    
    

    return (
        <div className="flex flex-col bg-white">
             <div className="w-full h-2 rounded-t bg-indigo-500"> 
                </div>
            <div className="flex flex-col w-full shadow-md rounded-b border border-solid border-gray-100">
               
                <div className="px-6 pt-6 pb-4 flex flex-col w-full  "> 
                    <div className="flex items-center mb-2">
                        <CubeTransparentIcon className="flex-shrink-0 -ml-1 mr-3 h-8 w-8 text-indigo-500" /> 
                        <div className="m-0 font-bold text-xl tracking-widest uppercase"> {module.name}</div>

                    </div>
                    <div className="text-base mt-4 mb-4 text-gray-900 dark:text-gray-500"> {module.description}</div>
                    <div className="flex items-center justify-end"> 
                        <button
                            type="button"
                            className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-gray-50 hover:bg-white"
                        >
                            Explore Module
                        </button>
                    </div>
                </div>
                <div className="py-2">
                    <div className="px-6 py-2 font-bold tracking-widest uppercase">Crash Course</div>

                    <div className=" border-t border-b border-gray-100 mx-6" />
                        
                    <div className="px-6  pb-4 flex flex-col w-full overflow-y-auto max-h-60">
                        <nav className="space-y-1" aria-label="Sidebar">
                            
                            {module.lessons.map((lesson) => (
                            <div key={lesson.id} className="text-gray-900 hover:bg-gray-50  group flex items-center px-3 py-2 text-sm font-medium rounded-md">
                                <LockClosedIcon className="flex-shrink-0 -ml-1 mr-3 h-5 w-5 text-indigo-500" /> 
                                <span className="truncate">{lesson.name}</span>
                            </div>
                            ))}
                        </nav>
                    </div>
                </div>


                <div className="py-2">
                    <div className="px-6 py-2 font-bold tracking-widest uppercase">Practice</div>

                    <div className=" border-t border-b border-gray-100 mx-6" />

                    <div className="px-6  pb-4 flex flex-col w-full overflow-y-auto max-h-60">
                        <nav className="space-y-1" aria-label="Sidebar">
                            
                            {module.lessons.map((lesson) => (
                            <div key={lesson.id} className="text-gray-900 hover:bg-gray-50  group flex items-center px-3 py-2 text-sm font-medium rounded-md">
                                <LockClosedIcon className="flex-shrink-0 -ml-1 mr-3 h-5 w-5 text-indigo-500" /> 
                                <span className="truncate">{lesson.name}</span>
                            </div>
                            ))}
                        </nav>
                    </div>
                </div>
                
            </div>
        </div>
    )
  }


  
   