





import { useState } from 'react'
import { Disclosure} from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import { Link } from 'react-router-dom';
import logo_partial from '../static/logo_partial.png';
import logo_full from '../static/logo_full.png';


import ProfileDropdown from './ProfileDropdown';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

export default function Navbar() {

    const [tabs, setTabs] = useState(
        [
            { name: 'Products', href: '/products', current: window.location.pathname === '/products' },
            { name: 'Team', href: '/team', current: window.location.pathname === '/team' },
            { name: 'Profile', href: '/profile', current: window.location.pathname === '/profile' },
            { name: 'Pricing', href: '/pricing', current: window.location.pathname === '/pricing' },
          ]
    );
    
  return (
    <Disclosure as="nav" className="bg-white shadow sticky top-0 z-50">
      {({ open }) => (
        <>
          <div className=" mx-auto px-2 tablet:px-6 desktop:px-8">
            <div className="relative flex justify-between h-16">
              <div className="absolute inset-y-0 left-0 flex items-center tablet:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex-1 flex items-center justify-center tablet:items-stretch tablet:justify-start">
                <div className="flex-shrink-0 flex items-center pr-2">
                <Link to="/" onClick={() => setTabs([
                            { name: 'Products', href: '/products', current: window.location.pathname === '/products' },
                            { name: 'Team', href: '/team', current: window.location.pathname === '/team' },
                            { name: 'Profile', href: '/profile', current: window.location.pathname === '/profile' },
                            { name: 'Pricing', href: '/pricing', current: window.location.pathname === '/pricing' },
                          ])} >
                  <img
                    className="block desktop:hidden h-8 w-auto"
                    src={logo_partial}
                    alt="Skilltree"
                  />
          
                  <img
                    className="hidden desktop:block h-8 w-auto"
                    src={logo_full}
                    alt="Skilltree"
                  />
                  </Link>
                </div>
                <div className="hidden tablet:ml-6 tablet:flex tablet:space-x-8">
                {tabs.map((tab) => (
                        <a
                        href={tab.href}
                        key={tab.name}
                        className={classNames(
                                tab.current ? 'border-indigo-500 text-gray-900 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium' : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium')}
                        onClick={() => setTabs([
                            { name: 'Products', href: '/products', current: window.location.pathname === '/products' },
                            { name: 'Team', href: '/team', current: window.location.pathname === '/team' },
                            { name: 'Profile', href: '/profile', current: window.location.pathname === '/profile' },
                            { name: 'Pricing', href: '/pricing', current: window.location.pathname === '/pricing' },
                          ])}
                        >
                        {tab.name}
                        </a>
                ))}
                 
                </div>
              </div>
              <div className="absolute inset-y-0 right-0 flex items-center pr-2 tablet:static tablet:inset-auto tablet:ml-6 tablet:pr-0">
               

               
                <ProfileDropdown/>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="tablet:hidden">
            <div className="pt-2 pb-4 space-y-1">
                {tabs.map((tab) => (
                  
                         <Disclosure.Button
                         as="a"
                         key={tab.name}
                         href={tab.href}
                         className={classNames(
                            tab.current ? 'bg-indigo-50 border-indigo-500 text-indigo-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium' : 'border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium')}
                         onClick={() => setTabs([
                            { name: 'Products', href: '/products', current: window.location.pathname === '/products' },
                            { name: 'Team', href: '/team', current: window.location.pathname === '/team' },
                            { name: 'Profile', href: '/profile', current: window.location.pathname === '/profile' },
                            { name: 'Pricing', href: '/pricing', current: window.location.pathname === '/pricing' },
                          ])}
                        >
                         {tab.name}
                       </Disclosure.Button>
                ))}
             
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}
